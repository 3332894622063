<template>
  <b-modal centered modal-class="delete_brand_model"
           id="modal-discard-v2" hide-footer
           hide-header>
    <div class="w-full h-full">
      <div @click="$bvModal.hide('modal-discard-v2')" class="float-right w-6 h-6 flex justify-end">
        <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 cursor-pointer" viewBox="0 0 12 12" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8161 2.24461C11.109 1.95172 11.109 1.47684 10.8161 1.18395C10.5232 0.891056 10.0483 0.891056 9.75546 1.18395L6.00007 4.93933L2.24469 1.18395C1.95179 0.891056 1.47692 0.891056 1.18403 1.18395C0.891132 1.47684 0.891132 1.95172 1.18403 2.24461L4.93941 5.99999L1.18403 9.75538C0.891132 10.0483 0.891132 10.5231 1.18403 10.816C1.47692 11.1089 1.95179 11.1089 2.24469 10.816L6.00007 7.06065L9.75546 10.816C10.0483 11.1089 10.5232 11.1089 10.8161 10.816C11.109 10.5231 11.109 10.0483 10.8161 9.75538L7.06073 5.99999L10.8161 2.24461Z" fill="black"/>
        </svg>
      </div>
      <div class="w-full px-10 flex flex-col items-center pt-2">
        <svg xmlns="http://www.w3.org/2000/svg" class="w-10 h-10" viewBox="0 0 40 40" fill="none">
          <path d="M20 38C29.9411 38 38 29.9411 38 20C38 10.0589 29.9411 2 20 2C10.0589 2 2 10.0589 2 20C2 29.9411 10.0589 38 20 38Z" stroke="#C13A52" stroke-width="3.6" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M25.3996 14.6016L14.5996 25.4016" stroke="#C13A52" stroke-width="3.6" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M14.5996 14.6016L25.3996 25.4016" stroke="#C13A52" stroke-width="3.6" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <p class="text-[#3C4549] font-poppins pt-[1.188rem] text-[1rem] font-bold leading-5">{{ modelTitle }}</p>
        <p class="pt-1 text-center text">{{modelDescription}}</p>

        <div class="pt-6 pb-11 flex gap-x-3 justify-between items-center">
          <Button
            id="canecl-button"
            type="button"
            class="border border-gray-500 hover:bg-[#F2F3F8]"
            buttonClass="btn-lg"
            @click="$bvModal.hide('modal-discard-v2')"
          >
            <template v-slot:label>Close</template>
          </Button>
          <Button
            id="dicard-button"
            type="button"
            class="text-white bg-[#C13A52]"
            buttonClass="btn-lg"
            @click="$emit('click')"
          >
            <template v-slot:label>Discard</template>
          </Button>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
export default {
  props: ['modelTitle', 'modelDescription'],
  components: {
    Button: () => import('@/ui/ui-kit/v2/Button.vue')
  }
}
</script>
<style lang="less">
.save_brand_model {
  .modal-dialog {
    max-width: 36.5rem !important;
  }
  .modal-content {
    border-radius: 16px !important;
    border-color: #F2F3F8 !important;
    padding: 16px !important;
  }
  .modal-body {
    padding: 0 !important;
  }
}

</style>
